import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function DismissOrientationDialog({
  open,
  onClose,
  onConfirm,
}: Props) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pt: 3 }} variant="h2">
        Are you sure you want to dismiss for now?
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography py={1} variant="body2">
          You still have to finish setting up your account! This banner will
          reappear later as a reminder.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pb: 3 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="pendo_web-anban-orientation-dismiss"
          color="primary"
          variant="contained"
          onClick={onConfirm}
        >
          Yes, dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
